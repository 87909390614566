<template>
  <div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-12 2xl:col-span-12">
        <!-- BEGIN: Change Password -->
        <div class="intro-y box lg:mt-5">
          <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base">{{ i18n('changePassword.title') }}</h2>
          </div>
          <div class="flex justify-center">

            <div class="grid-cols-8">
              <div class="p-5 col-span-8">
                <div class="mt-3">
                  <label for="change-password-form-1" class="form-label">
                    {{ i18n('changePassword.oldPassword') }}
                  </label>
                  <input
                    id="change-password-form-1"
                    type="password"
                    class="form-control dark:bg-dark-9"
                    :placeholder="i18n('changePassword.oldPassword')"
                    v-model="form.oldPassword"
                  />
                </div>
                <div class="mt-3">
                  <label for="change-password-form-2" class="form-label">
                    {{ i18n('changePassword.newPassword') }}
                  </label>
                  <input
                    id="change-password-form-2"
                    type="password"
                    class="form-control dark:bg-dark-9"
                    :placeholder="i18n('changePassword.newPassword')"
                    v-model="form.newPassword"
                  />
                </div>
                <div class="mt-3">
                  <label for="change-password-form-3" class="form-label">
                    {{ i18n('changePassword.confirmPassword') }}
                  </label>
                  <input
                    id="change-password-form-3"
                    type="password"
                    class="form-control dark:bg-dark-9"
                    :placeholder="i18n('changePassword.confirmPassword')"
                    v-model="form.confirmPassword"
                  />
                </div>
                <div class="flex flex-col sm:flex-row justify-center items-center gap-2 mt-5 sm:mt-10">
                  <AppButton
                    type="button"
                    class="btn bg-theme-31 text-white cursor-pointer"
                    :class="!saveLoading ? 'w-full sm:w-40' : ''"
                    :disabled="loading"
                    :loading="loading"
                    @click="doSubmit()"
                  >
                    <strong>{{ i18n('changePassword.changePassword') }}</strong>
                    <template v-slot:loading>
                      <app-i18n code="common.loading"></app-i18n>
                      <LoadingIcon icon="three-dots" color="#FFFFFF" style="margin: 0 4px;" />
                    </template>
                  </AppButton>
                  <button type="button" class="btn bg-theme-32 text-black w-full sm:w-40" :disabled="loading" @click="doCancel()">
                    {{ i18n('common.cancel') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Change Password -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'

export default defineComponent({
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.changePassword')
  },
  watch: {
    isRTL() {
      document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.changePassword')
    }
  },
  data() {
    return {
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      loading: 'auth/loadingChangeMyPassword'
    })
  },
  methods: {
    ...mapActions({
      doChangeMyPassword: 'auth/doChangeMyPassword'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      for (var key in this.form) {
        var value = this.form[key]
        if (value.length == 0) {
          this.errorMessage = 'changePassword.errors.emptyFields'
          return false
        }
      }
      if (this.form.newPassword != this.form.confirmPassword) {
        this.errorMessage = 'changePassword.errors.passwordsDontMatch'
        return false
      }
      return true
    },
    doCancel() {
      this.$router.push('/profile')
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      await this.doChangeMyPassword({
        oldPassword: this.form.oldPassword,
        newPassword: this.form.newPassword
      })
    }
  }
})
</script>
